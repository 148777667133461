    /* You can add global styles to this file, and also import other style files */
    
    @font-face {
        font-family: 'Mulish';
        src: url(assets/font/Mulish/Mulish-VariableFont_wght.ttf);
    }
    
    * {
        font-family: 'Mulish';
    }
    
    .tag-secondary {
        background: #05c3fb !important;
    }
    
    .font-bold {
        font-weight: bold;
    }
    
    .point {
        cursor: pointer;
    }
    
    .header-brand-img {
        line-height: 2rem;
        vertical-align: inherit;
        width: auto;
    }
    
    .form-control {
        /*  background: #F6F6FA !important; */
        border: 1px solid #EFF0F6;
    }
    
    .option-table {
        font-family: 'Mulish';
        font-style: normal !important;
        font-weight: 500 !important;
        font-size: 14px !important;
        line-height: 18px !important;
        letter-spacing: 0.2px !important;
        color: #000000 !important;
    }
    
    .message {
        font-family: 'Mulish';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.2px;
        color: #A4A6B3;
        text-align: justify;
    }
    
    .subMessage {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0.2px;
        color: #727588;
        text-align: justify;
    }
    
    .”green-detail” {
        color: #37b5ca;
    }
    
    .”purple-detail” {
        color: #9c6dfe;
    }
    
    .mat-snack-bar-container {
        color: #fff !important;
    }
    
    .mat-simple-snackbar-action {
        color: #fff !important;
    }
    
    .StripeElement {
        border-radius: 2px;
        padding: 0.8rem 0.75rem;
        font-size: 0.875rem;
        height: 48px !important;
        background: #F6F6FA !important;
        border: 1px solid #EFF0F6 !important;
    }
    
    .page-title {
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 25px;
        letter-spacing: 0.2px;
        color: #363740;
    }
    
    .card-title {
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 25px;
        letter-spacing: 0.2px;
        color: #363740;
    }
    
    .card-sub-title {
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        align-items: center;
        letter-spacing: 0.2px;
        color: #363740;
    }
    
    .card-header {
        background-color: transparent !important;
    }
    
    .modal-title {
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 25px;
        letter-spacing: 0.2px;
        color: #363740;
    }
    
    label {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.2px;
        color: #363740;
    }
    
    .tabs-menu-boxed ul li a {
        padding: 20px 15px 20px 15px;
        border-radius: 1px 0px 0 0;
        font-size: 16px;
        line-height: 20px;
        display: flex;
        align-items: center;
        letter-spacing: 0.2px;
        color: #A4A6B3;
        font-weight: 400;
    }
    
    .table thead th {
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.2px;
        color: #9FA2B4;
        text-transform: inherit;
        padding-left: 1rem;
    }
    
    .table .thead-special-data-bank th {
        font-weight: 700;
        padding: 2rem 1rem 1rem;
    }
    
    .table .thead-special-bank-list td {
        padding: 1rem;
    }
    
    .table tbody td {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.2px;
        color: #252733;
        padding-left: 1rem;
    }
    
    .table tfoot th {
        font-style: normal;
        font-weight: 700 !important;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.2px;
        text-transform: inherit;
        padding-left: 1rem;
        color: #252733;
    }
    
    .table td {
        padding: 0.73rem;
        vertical-align: initial;
        border-top: 0;
    }
    
    table.dataTable td.dataTables_empty,
    table.dataTable th.dataTables_empty {
        text-align: center;
        padding-top: 1.73rem;
        padding-bottom: 1.73rem;
    }
    
    .page-link {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        letter-spacing: 0.3px;
        color: #000000;
    }
    
    .page-item.active .page-link {
        z-index: 3;
        color: #fff;
        background-color: #352CAB;
        border-color: #352CAB;
    }
    
    .page-item.disabled .page-link {
        color: #ced4da !important;
    }
    
    .round label {
        background-color: #fff;
        border: 2px solid rgba(0, 0, 0, 0.54);
        border-radius: 3px;
        cursor: pointer;
        height: 18px;
        left: 0;
        position: relative;
        top: 0;
        width: 18px;
    }
    
    .round label:after {
        border: 2px solid #fff;
        border-top: none;
        border-right: none;
        content: "";
        height: 6px;
        left: 1px;
        opacity: 0;
        position: absolute;
        top: 2px;
        transform: rotate(-45deg);
        width: 12px;
    }
    
    .round input[type='checkbox'] {
        visibility: hidden !important;
    }
    
    .round input[type='checkbox']:checked+label {
        background-color: rgba(0, 0, 0, 0.54);
        border-color: rgba(0, 0, 0, 0.54);
    }
    
    .round input[type='checkbox']:checked+label:after {
        opacity: 1;
    }
    
    .sub-round label {
        border-radius: 3px;
        cursor: pointer;
        height: 18px;
        left: 0;
        position: relative;
        top: 0;
        width: 18px;
        background: #F6F6FA;
        border: 1px solid #EFF0F6;
        box-sizing: border-box;
    }
    
    .sub-round label:after {
        border: 2px solid #fff;
        border-top: none;
        border-right: none;
        content: "";
        height: 6px;
        left: 3px;
        opacity: 0;
        position: absolute;
        top: 4px;
        transform: rotate(-45deg);
        width: 12px;
    }
    
    .sub-round input[type='checkbox'] {
        visibility: hidden !important;
    }
    
    .sub-round input[type='checkbox']:checked+label {
        background-color: rgba(0, 0, 0, 0.54);
        border-color: rgba(0, 0, 0, 0.54);
    }
    
    .sub-round input[type='checkbox']:checked+label:after {
        opacity: 1;
    }
    
    .dataTables_processing {
        color: #fff0 !important;
    }
    
    .dataTables_processing {
        position: absolute !important;
        top: 50% !important;
        bottom: 0 !important;
        margin: 15px 0 15px !important;
        z-index: 5 !important;
        height: 50px !important;
        width: 50px !important;
        border: 2px solid #352cab7d !important;
        border-right-color: transparent !important;
        border-radius: 50%!important;
        border-right-color: transparent !important;
        -moz-border-radius: 80px;
        -webkit-border-radius: 80px;
        -ms-border-radius: 80px;
        -o-border-radius: 80px;
        box-shadow: 0 0 20px transparent !important;
        -mozbox-shadow: 0 0 20px transparent !important;
        -ms-box-shadow: 0 0 20px transparent !important;
        -o-box-shadow: 0 0 20px transparent !important;
        -webkit-box-shadow: 0 0 20px transparent !important;
        -webkit-animation: cwSpin 1s linear .2s infinite;
        -moz-animation: cwSpin .666s linear .2s infinite;
        -o-animation: cwSpin .666s linear .2s infinite;
        -ms-animation: cwSpin .666s linear .2s infinite;
        animation: cwSpin .666s linear .2s infinite;
    }
    
    .dataTables_wrapper .dataTables_processing {
        position: absolute !important;
        top: 80% !important;
        right: 0 !important;
        bottom: 0 !important;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }
    
    div.dataTables_wrapper div.dataTables_processing {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 200px;
        margin-left: -100px;
        margin-top: -26px;
        text-align: center;
        padding: 1em 0;
    }
    
    .custom-switch-indicator {
        background: #828690 !important;
    }
    
    .custom-switch-input:checked~.custom-switch-indicator {
        background: #6c5ffc !important;
    }
    
    table.altura.dataTable tbody td {
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
    }
    
    .icons-list-item {
        border: 0px solid #e9ebfa;
    }
    
    .star i {
        font-size: 125%;
        vertical-align: middle;
        color: rgba(0, 0, 0, 0.54);
    }
    
    .avatar-list .bg-success {
        background: transparent !important;
    }
    
    .avatar-list .bg-success:hover {
        background: #e9ebfa !important;
    }
    /* btn generales */
    
    .btn {
        font-weight: 700;
        font-size: 16px;
        border-radius: 3px;
        height: 45px;
    }
    
    .btn-outline-secondary-general {
        color: #02B5CA;
        background-color: transparent;
        background-image: none;
        border-color: #02B5CA;
        width: 200px;
    }
    
    .btn-secondary-general {
        border-color: #02B5CA;
        background: #02B5CA;
        color: #fff;
        width: 200px;
    }
    
    .btn-default-general {
        border-color: #CFD2DD;
        background: #CFD2DD !important;
        color: #fff;
        width: 200px;
    }
    
    .btn-purple-general {
        border-color: #9C6CFE;
        background: #9C6CFE !important;
        color: #fff;
        width: 200px;
    }
    
    .btn-outline-purple-general {
        color: #9C6CFE;
        background-color: transparent;
        background-image: none;
        border-color: #9C6CFE;
        width: 200px;
    }
    
    .btn-orange-general {
        border-color: #F45D2D;
        background: #F45D2D !important;
        color: #fff;
        width: 200px;
    }
    
    .btn-outline-dark-general {
        color: #363740;
        background-color: transparent;
        background-image: none;
        border-color: #C5C7CD;
        width: 151px;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        align-items: center;
        text-align: center;
        letter-spacing: 0.2px;
    }
    
    .btn-blue-general {
        border-color: #2D40B4;
        background: #2D40B4 !important;
        color: #fff;
        width: 200px;
    }
    
    .btn-outline-blue-general {
        color: #2D40B4;
        background-color: transparent;
        background-image: none;
        border-color: #2D40B4;
        width: 200px;
    }
    
    .green {
        color: #39bacd !important;
    }
    
    .”purple” {
        color: #9c6dfe;
    }
    
    .”green” {
        color: #3EBACD;
    }
    
    .position-inherit {
        position: inherit !important;
    }
    
    .pl-0 {
        padding-left: 0px;
    }
    
    .form-date {
        background: #FFFFFF !important;
        border: 1.5px solid #EEEEEE !important;
        box-sizing: border-box !important;
        border-radius: 3px !important;
    }
    
    .input-group-text {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
        align-items: center;
        padding: 0.64rem 0.75rem;
        margin-bottom: 0;
        font-size: 0.9375rem;
        font-weight: 400;
        line-height: 1.2;
        height: 48px;
        color: #fff;
        text-align: center;
        white-space: nowrap;
        border: 1px solid #e9edf4;
        color: #9c6dfe !important;
    }
    /* background badge   */
    
    .gray-badge {
        background: #DADDE1 !important;
    }
    
    .red-badge {
        background: #f3eaea !important;
    }
    
    .green-badge {
        background: #29CC97 !important;
    }
    
    .yellow-badge {
        background: #FFA740 !important;
    }
    
    .yellow-text {
        color: #FFA740 !important;
    }
    
    .orange-badge {
        background: #F45D2D !important;
    }
    
    .orange-text {
        color: #F45D2D !important;
    }
    
    .badge-size {
        padding: 7px 8px 7px 8px;
        width: 100px;
    }
    /* badge digitalization */
    
    .green-badge-digitalization {
        background: #E2FFF6 !important;
        color: #29CC97 !important;
    }
    
    .red-badge-digitalization {
        background: #FFEAEA !important;
        color: #FF0000 !important;
    }
    
    .gray-badge-digitalization {
        background: #EFF0F4 !important;
        color: #000 !important;
    }
    
    .yellow-text-digitalization {
        background: #FFF1E0 !important;
        color: #FFA740 !important;
    }
    
    .modal-backdrop-ticket {
        background-color: #1d1c1b4f;
    }
    
    .modal-view-pdf {
        .modal-dialog {
            max-width: 700px;
            margin: 1.75rem auto;
            .modal-content {
                border-radius: 0px !important;
            }
        }
    }
    /* end badge digitalization */
    
    .img-action {
        height: 26px;
    }
    
    .img-download {
        max-height: 19px;
    }
    
    .rtl #chartBar1 {
        width: 100% !important;
    }
    
    .table-rate-retum {
        background: rgba(2, 181, 202, 0.1);
    }
    
    .icons-datatable {
        height: 20px;
    }
    
    .icons-datatable-special {
        padding: 0 !important;
        vertical-align: middle !important;
        max-width: 10rem !important;
    }
    
    .img-user-table {
        width: 30px;
        height: 30px;
    }
    
    @media (min-width: 992px) {
        .sidebar-mini.sidenav-toggled .app-header.header-2 {
            padding-left: 100px;
        }
    }
    
    .popover {
        max-width: 312px !important;
        top: 5.5rem !important;
        margin-right: 2rem !important;
    }
    
    .popover-header {
        border-bottom: 0px solid #ebebeb;
        font-family: 'Mulish';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.2px;
        color: #000000;
    }
    
    .popover-body {
        padding: 15px;
        font-family: 'Mulish';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0.2px;
        color: #5D6273;
    }
    
    .popover-body .btn-popover {
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.2px;
        height: 35px;
        background: #352CAB;
        border-radius: 3px;
        color: #ffffff !important;
    }
    
    .bs-popover-start>.popover-arrow {
        margin: -5.95rem -26px;
    }
    
    .bs-popover-start>.popover-arrow::before {
        border-width: 0rem 0px 1.5rem 3.5rem;
    }
    
    .bs-popover-start>.popover-arrow::after {
        border-width: 0rem 0 2.5rem 3.5rem;
    }
    
    .richText-dropdown {
        max-height: 300px;
        overflow: auto;
        scrollbar-color: red yellow !important;
    }
    
    strong,
    b {
        font-weight: bold;
    }
    
    .richText-dropdown::-webkit-scrollbar {
        width: 8px;
    }
    /* 
.richText-dropdown::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(200,200,200,1);
    border-radius: 10px;
} */
    
    .pointer {
        cursor: pointer !important;
    }
    
    .richText-dropdown::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: #fff;
        -webkit-box-shadow: inset 0 0 6px rgba(90, 90, 90, 0.7);
    }
    
    #search {
        background: #F6F6FA !important;
        /*  border-right: 0px !important; */
        margin: 0;
    }
    
    .search {
        background: #F6F6FA !important;
        /*  border-right: 0px !important; */
        margin: 0;
    }
    
    .input-group-text {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-top-right-radius: 0;
        border-bottom-right-radius: 3px;
        background: #F6F6FA !important;
        box-sizing: border-box;
    }
    
    .table-special-question td {
        padding: 0.58rem;
        vertical-align: middle;
    }
    
    .table-special-section td {
        padding: 0.73rem;
        vertical-align: middle;
    }
    
    .img-expenses {
        vertical-align: initial;
        height: 14px;
        width: 14px;
    }
    
    .alert-info-general {
        background: rgba(2, 181, 202, 0.05);
        border: 1px solid rgba(2, 181, 202, 0.05);
        border-radius: 3px;
    }
    
    .alert-info-general.title {
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.2px;
        color: #363740;
    }
    
    .modal-phenergy-contract .modal-dialog {
        max-width: 650px !important;
        margin: 0rem auto;
    }
    
    .modal-bill .modal-dialog {
        max-width: 467px !important;
    }
    
    .modal-load-pgc .modal-dialog {
        max-width: 950px !important;
        margin: 0rem auto;
    }
    /* .modal-add-clients .modal-dialog {
    max-width: 880 !important;
} */
    
    .modal-save-client {
        .modal-dialog {
            max-width: 880px;
            /* .modal-content {
            border-radius: 0px !important;
        } */
        }
    }
    
    .customBackdropSon {
        z-index: 9999 !important;
    }
    
    .padding-icon-treasury {
        padding-right: 1.5rem !important;
    }
    
    .btn-treasury {
        font-size: 14px;
        border-radius: 100px;
        font-style: normal;
        font-weight: 700;
        font-size: 11px;
        line-height: 14px;
        display: block;
        align-items: center;
        text-align: center;
        letter-spacing: 0.5px;
        color: #FFFFFF;
        width: 95px;
        height: 27px;
    }
    
    .color-provitional {
        background: #FFD337 !important;
    }
    
    .color-real {
        background-color: #29CC97;
    }
    
    .color-recibido {
        background-color: #FFA740;
    }
    
    .color-conciliado {
        background: #EFEFF6 !important;
        color: #050505 !important;
    }
    
    .color-payment {
        background: #EFEFF7 !important;
        border-radius: 100px;
        color: #252733;
        box-shadow: 0 0 1px 1px rgb(0 0 0 / 10%);
    }
    
    .color-conciliate {
        background: #EFEFF7 !important;
        border-radius: 100px;
        color: #252733;
        box-shadow: 0 0 1px 1px rgb(0 0 0 / 10%);
        width: inherit !important;
    }
    
    .color-concilation {
        background: #29CC97 !important;
    }
    
    .color-Received {
        background: #02B5CA !important;
    }
    
    .color-Confirmado {
        background: #FFA740 !important;
    }
    
    .color-import-treasury-red {
        color: #F45D2D;
    }
    
    .color-import-treasury-greed {
        color: #18B481;
    }
    
    .size-test {
        height: 21px;
        width: 56px;
        margin: 0 auto;
    }
    
    .clickDatatable {
        cursor: pointer;
    }
    
    .textNowRapDatatable {
        white-space: nowrap;
    }
    
    .border-conciliation {
        border-bottom: 0.5px solid #A4A6B3;
    }
    
    .class-input {
        color: #02B5CA;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.2px;
    }
    
    .class-output {
        color: #9C6CFE;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.2px;
    }
    
    .form-select-table {
        background: #FFFFFF !important;
        border: 1.5px solid #EEEEEE !important;
        box-sizing: border-box !important;
        border-radius: 3px !important;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e") !important;
        background-repeat: no-repeat !important;
        background-position: right .75rem center !important;
        background-size: 16px 12px !important;
        height: 45px;
    }
    
    .table-banking tbody {
        td {
            vertical-align: baseline;
            /*  padding-bottom: 25px !important;
            padding-top: 15px !important; */
            padding-bottom: 10px !important;
            padding-top: 10px !important;
            text-align: left;
            width: 20%;
        }
    }
    
    .padding-td-check {
        width: 0% !important;
    }
    
    .table-banking td.dataTables_empty,
    th.dataTables_empty {
        width: 647rem !important;
    }
    
    .table-anticipation tbody td {
        vertical-align: baseline;
        padding-bottom: 10px !important;
        padding-top: 10px !important;
        /*  padding-bottom: 29px !important;
        padding-top: 15px !important; */
        text-align: start;
        width: 15%;
    }
    
    .table-anticipation td.dataTables_empty,
    th.dataTables_empty {
        width: 647rem !important;
    }
    
    .selectSpan {
        font-style: normal !important;
        font-weight: 700 !important;
        font-size: 14px !important;
        line-height: 20px !important;
        letter-spacing: 0.2px !important;
        color: #9C6CFE;
    }
    
    .table-special-section-form td {
        padding: 0.73rem;
    }
    
    .label-divisa {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        text-align: right;
        letter-spacing: 0.2px;
        color: #2D40B4;
    }
    
    .p-tooltip {
        text-align: left;
    }
    
    .tooltip {
        z-index: 100000 !important;
    }
    
    .tooltip-inner {
        background-color: rgba(93, 98, 115, 0.9);
    }
    
    .bs-tooltip-end .tooltip-arrow::before,
    .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before {
        border-right-color: rgba(93, 98, 115, 0.9);
        ;
    }
    
    #test {
        background: #F6F6FA !important;
        border-right: 0px !important;
        margin: 0;
    }
    
    .custom-control-input:checked~.custom-control-label-pgc::before {
        background-color: #ffffff !important;
        border-color: var(--primary-bg-color);
    }
    
    .custom-control-input:checked~.custom-control-label-pgc::affer {
        background: #F6F6FA;
        border: 1px solid #EFF0F6;
        box-sizing: border-box;
        border-radius: 3px;
    }
    
    .custom-control-label-pgc::after {
        background: #F6F6FA;
        border: 2px solid #EFF0F6;
        box-sizing: border-box;
        border-radius: 3px;
    }
    
    .custom-checkbox-pgc .custom-control-input:checked~.custom-control-label-pgc::after {
        background-image: url(/assets/icon/checked.svg);
        background-repeat: no-repeat;
    }
    
    .custom-control-label-pgc {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0.2px;
        color: #5D6273;
    }
    
    .padding-pgc td {
        padding: 1rem !important;
        vertical-align: middle;
    }
    
    .padding-pgc th {
        padding-top: 2rem !important;
        font-weight: 700 !important;
    }
    
    .width-download {
        max-width: 50px;
    }
    
    table.dataTable .billTicket tr:nth-child(1) {
        background-color: #FAFAFC;
    }
    
    .table .modal-invoice-ticket td {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.2px;
        color: #252733;
        padding: 0.8rem 1rem 0.5rem 1rem;
    }
    
    .modal-invoice-ticket .modal-dialog {
        max-width: 953px !important;
        /* margin: 0rem auto; */
    }
    
    div.dataTables_wrapper div.dataTables_filter input {
        width: 22rem !important;
        height: 48px !important;
        margin-left: 0px !important;
        border-radius: 3px;
        background-image: url("assets/icon/search-gray.svg") !important;
        background-repeat: no-repeat !important;
        background-position: right 0.75rem center !important;
    }
    
    .img-datatable {
        max-width: 10rem;
    }
    
    @media screen and (max-width: 1400px) and (min-width: 320px) {
        div.dataTables_wrapper div.dataTables_filter input {
            width: 17rem !important;
        }
    }
    
    @media screen and (max-width: 414px) and (min-width: 320px) {
        .note-mybutton {
            display: block;
        }
    }
    
    @media (min-width: 992px) {
        .app.sidebar-mini.sidenav-toggled .side-menu__item {
            padding: 15px 30px;
        }
        .sidebar-mini.sidenav-toggled.sidenav-toggled-open .side-menu__item {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            padding: 10px 20px !important;
            position: relative;
        }
    }
    /* .note-mybutton .btn-group {
    margin-right: 0.5rem;
} */
    
    .table .tbody-notification td {
        padding: 1.2rem !important;
    }
    
    #back-to-top {
        bottom: 95px;
        right: 31px;
    }
    
    .color-renew {
        background: #ff0000 !important;
    }
    
    .btn-calendar {
        background-color: #dee2e65e;
        padding: 0.5rem;
    }
    
    .color-activate {
        background: #AEB1C2!important;
        font-style: normal;
        font-weight: 700;
        font-size: 11px;
        line-height: 14px;
        letter-spacing: 0.5px;
        color: #FFFFFF;
    }
    /* ngbDatepicker */
    
    .ngb-dp-arrow-btn {
        height: 34px !important;
    }
    
    .btn-datepicker {
        background: transparent !important;
        border-color: transparent !important;
        color: blue !important;
        font-size: 12px;
        font-weight: normal;
    }
    
    .modal-invest {
        .modal-dialog {
            max-width: 903px;
            margin: 1.75rem auto;
        }
    }
    
    .modal-loans {
        .modal-dialog {
            max-width: 903px;
            margin: 1.75rem auto;
        }
    }
    /* 
.color-month {
    background: rgba(245, 241, 255, 1);
}

.styleBudget- {
    background: rgba(247, 247, 252, 1) !important;
} */
    
    .gauge-chart>span {
        font-style: normal !important;
        font-weight: 400 !important;
        font-size: 14px !important;
        line-height: 18px !important;
        text-align: center !important;
        letter-spacing: 0.2px !important;
        text-transform: uppercase !important;
        color: #6B7280 !important;
        margin-top: 0px !important;
    }
    
    .disabledBtn {
        color: #adb1b6 !important
    }
    
    .side-header {
        border-right: 0px solid #e9edf4 !important;
    }
    
    .app-sidebar {
        border-right: 0px solid #e9edf4 !important;
    }
    
    .alert-input-general {
        border: 1px solid #e82646 !important;
    }
    
    .listaguion li {
        list-style-type: none;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 20px;
        letter-spacing: 0.2px;
        color: #FFFFFF;
    }
    
    .listaguion li:before {
        content: "-"
    }
    
    .style-register {
        max-width: 242px !important;
    }
    
    .size-modal {
        max-width: 348px;
    }
    
    .border-menu {
        border-bottom: 0.5px solid #A4A6B3 !important;
    }
    
    .toggle {
        cursor: pointer;
    }
    
    .ng2-pdf-viewer-container {
        overflow-x: clip !important;
    }
    
    .w-13 {
        width: 13% !important;
    }
    
    .w-18 {
        width: 18% !important;
    }
    
    .w-41 {
        width: 41% !important;
    }
    
    @media screen and (min-width: 360px) and ( max-width: 640px) {
        .w-10 {
            width: inherit !important;
        }
        .w-13 {
            width: inherit !important;
        }
        .w-18 {
            width: inherit !important;
        }
        .w-41 {
            width: inherit !important;
        }
    }
    
    @media screen and (min-width: 640px) and ( max-width: 764px) {
        .w-10 {
            width: inherit !important;
        }
        .w-13 {
            width: inherit !important;
        }
        .w-18 {
            width: inherit !important;
        }
        .w-41 {
            width: inherit !important;
        }
    }
    
    .font-init {
        background-color: #e7e7f0 !important;
    }
    
    .table-primary-init>td {
        background-color: #e7e7f0 !important;
    }
    
    .table-primary-gren>th,
    .table-primary-gren>td {
        // background-color: #E2FCFF;
        background-color: #EEFDFF;
    }
    
    .table-primary-purpuple>th,
    .table-primary-purpuple>td {
        background-color: #F6F1FF;
    }
    
    .table-primary-grey>th,
    .table-primary-grey>td {
        background-color: #F7F7FC;
    }
    
    .table-primary-grey>td>span {
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0.5px;
        color: #363740;
    }
    
    .img-collect {
        width: inherit !important;
    }
    
    .margin-collect {
        margin-right: 25px;
    }
    
    .padding-td-bank {
        .table tbody td {
            padding-bottom: 29px !important;
            padding-top: 15px !important;
        }
    }
    
    .padding-td-prevision {
        /*  padding-bottom: 29px !important;
        padding-top: 15px !important; */
        width: 25% !important;
    }
    
    .padding-td-bank-mount {
        .table tbody td {
            padding-bottom: 29px !important;
            padding-top: 15px !important;
            text-align: right !important;
        }
    }
    
    .bank-round label {
        border-radius: 15px;
        cursor: pointer;
        height: 18px;
        left: 0;
        position: relative;
        top: 0;
        width: 18px;
        background: #F6F6FA;
        //border: 1px solid #EFF0F6;
        border: 1px solid #02b5ca !important;
        box-sizing: border-box;
    }
    
    .bank-round label:after {
        border: 2px solid #fff;
        border-top: none;
        border-right: none;
        content: "";
        height: 6px;
        left: 3px;
        opacity: 0;
        position: absolute;
        top: 4px;
        transform: rotate(-45deg);
        width: 12px;
    }
    
    .bank-round input[type='checkbox'] {
        visibility: hidden !important;
    }
    
    .bank-round input[type='checkbox']:checked+label {
        background-color: #02b5ca !important;
        border-color: #02b5ca;
    }
    
    .bank-round input[type='checkbox']:checked+label:after {
        opacity: 1;
    }
    
    // check prevision
    .forecast-round label {
        border-radius: 3px;
        cursor: pointer;
        height: 18px;
        left: 0;
        position: relative;
        top: 0;
        width: 18px;
        background: #F6F6FA;
        //border: 1px solid #EFF0F6;
        border: 1px solid #02b5ca !important;
        box-sizing: border-box;
    }
    
    .forecast-round label:after {
        border: 2px solid #fff;
        border-top: none;
        border-right: none;
        content: "";
        height: 6px;
        left: 3px;
        opacity: 0;
        position: absolute;
        top: 4px;
        transform: rotate(-45deg);
        width: 12px;
    }
    
    .forecast-round input[type='checkbox'] {
        visibility: hidden !important;
    }
    
    .forecast-round input[type='checkbox']:checked+label {
        background-color: #02b5ca !important;
        border-color: #02b5ca;
    }
    
    .forecast-round input[type='checkbox']:checked+label:after {
        opacity: 1;
    }
    
    .disabled-forecast {
        pointer-events: none !important;
        opacity: 0.4 !important;
    }
    
    .type-input-edite {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.2px;
        color: #252733;
        padding-left: 1rem;
    }
    
    .input-no-border-edit {
        background: #ffffff !important;
        border: 1px solid #eff0f600 !important;
        text-align: left !important;
        text-decoration: underline;
    }
    
    .span-edit-colections {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.2px;
        text-decoration-line: underline;
        color: #252733;
    }
    
    .sub-underline {
        text-decoration-line: underline;
    }
    
    .custom-control-label::after {
        position: absolute;
        top: 0.25rem;
        left: 0;
        display: block;
        width: 1rem;
        height: 1rem;
        content: "";
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 50% 50%;
    }
    
    .modal-treasury-box {
        .modal-dialog {
            max-width: 1300px;
            margin: 1.75rem auto;
            /* .modal-content {
            border-radius: 5px !important;
        } */
        }
    }
    
    .disabled-modal-treasury {
        z-index: 1055;
        .modal-treasury-box {
            z-index: -1 !important;
        }
    }
    
    .modal-assignetn {
        .modal-dialog {
            max-width: 448px;
        }
    }
    
    .style-elipce {
        align-items: center !important;
        white-space: nowrap !important;
        text-overflow: ellipsis !important;
        overflow: hidden !important;
        font-style: normal !important;
    }
    
    .font-w {
        font-weight: bold;
    }
    
    .text-uncover {
        text-decoration: underline;
    }
    
    .margin-delete-conciliate {
        margin-left: 25px;
    }
    /* forecast scrooll */
    
    #forecast {
        border-collapse: collapse;
        width: 100%;
        word-break: break-all;
    }
    
    #forecast thead,
    #forecast tbody {
        display: block;
    }
    
    #forecast thead tr {
        text-align: center;
        width: 100%;
        display: block;
    }
    
    #forecast tbody {
        position: relative;
        overflow: auto;
        width: 100%;
        max-height: 45vh;
    }
    
    // bankingTransactions scroll
    #bankingTransactions {
        border-collapse: collapse;
        width: 100%;
        word-break: break-all;
    }
    
    #bankingTransactions thead,
    #bankingTransactions tbody {
        display: block;
    }
    
    #bankingTransactions thead tr {
        text-align: center;
        width: 100%;
        display: block;
    }
    
    #bankingTransactions tbody {
        position: relative;
        overflow: auto;
        width: 100%;
        max-height: 45vh;
        /*   height: 35vh;
        overflow-y: auto;
        overflow-x: hidden; */
    }
    
    @media screen and (min-width: 360px) and ( max-width: 640px) {
        #bankingTransactions thead,
        #bankingTransactions tbody {
            display: contents;
        }
        #bankingTransactions thead tr {
            text-align: center;
            width: 100%;
            display: contents;
        }
        #forecast thead,
        #forecast tbody {
            display: contents;
        }
        #forecast thead tr {
            text-align: center;
            width: 100%;
            display: contents;
        }
    }
    
    .text-wrap-conciliation {
        font-size: 14px;
        line-height: 0 !important;
    }
    
    .was-validated .form-control:invalid,
    .form-control.is-invalid,
    .was-validated .form-select:invalid,
    .form-select.is-invalid {
        border-color: #e23e3d !important;
    }
    
    .span-table-number-card {
        color: var(--grayscale-black, #252733);
        font-family: Mulish;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;
        /* 142.857% */
        letter-spacing: 0.2px;
    }
    
    .yellow-card {
        background-color: #FFD337 !important;
    }
    
    .style-size-letter-card {
        color: var(--grayscale-white, #FFF);
        text-align: center;
        font-family: Mulish;
        font-size: 11px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 0.5px;
    }
    
    .date-caducade {
        color: #F45D2D;
        font-family: Mulish;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        /* 142.857% */
        letter-spacing: 0.2px;
    }
    
    .date-for-caducade {
        color: #EDBE1A;
        font-family: Mulish;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        /* 142.857% */
        letter-spacing: 0.2px;
    }
    
    .date-Current {
        color: var(--grayscale-black, #252733);
        font-family: Mulish;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        /* 142.857% */
        letter-spacing: 0.2px;
    }
    
    .style-general-card-datatables {
        color: var(--grayscale-black, #252733);
        font-family: Mulish;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        /* 142.857% */
        letter-spacing: 0.2px;
    }
    
    .treasury-income {
        .spand-description {
            white-space: normal;
            max-width: 277px;
            line-height: 1.5em;
            height: 3em;
            word-wrap: break-word;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            text-align: justify;
        }
    }
    
    .margin-demo {
        top: 123px !important;
    }
    .expanded-tbody {
         tbody {
            td {
               height: 46px !important;
            }
        }
        
    }

    .overflow-scroos {
        height: 576px;
        //overflow-y: auto;
        box-sizing: border-box;
        padding: 0rem;
        //overflow-x: clip;
        -ms-overflow-style: none;  /* Internet Explorer and Edge */
        scrollbar-width: none;  /* Firefox */
    
        /* Hide the scrollbar for Chrome, Safari and Opera */
        &::-webkit-scrollbar {
           display: none;
        }
    }
    /* Estilos para motores Webkit y blink (Chrome, Safari, Opera... )*/
    .overflow-scroos::-webkit-scrollbar {
        -webkit-appearance: none;
    }
    .overflow-scroos::-webkit-scrollbar:vertical {
        width: 1px;
    }
    .overflow-scroos::-webkit-scrollbar-button:increment,
    .overflow-scroos::-webkit-scrollbar-button {
        display: none;
    }
    .overflow-scroos::-webkit-scrollbar:horizontal {
        height: 10px;
    }
    .overflow-scroos::-webkit-scrollbar-thumb {
        background-color: #C2C9D1;
        /* border-radius: 20px; */
        border: 1px solid #C2C9D1;
    }
    .overflow-scroos::-webkit-scrollbar-track {
        border-radiu .label-sub-none {
            font-style: normal;
            font-size: 15px;
            line-height: 22px;
            align-items: center;
            color: #000000;
            padding: 0.5rem 0rem !important;
        }
        .disables-text {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 19px;
            align-items: center;
            color: #0e15a1 !important;
        }
    }
    .ms-choice {
        height: calc(2.6875rem + 2px) !important;
        border: 1px solid #DFE1E8;
      }